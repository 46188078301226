<template>
  <base-modal
    container-class="z-40"
    :modal-title="`${mode === 'assign' ? 'Assign' : 'Re-Assign'} Task`"
    @visible="fetchUsersList()"
    @hidden="reset()"
  >
    <template #modalBody>
      <div class="px-6 py-5">
        <base-input
          v-model="userName"
          type="text"
          container-class="mb-4"
          placeholder="Search User..."
        />
        <div class="flex mb-4">
          <base-button
            class="mr-3 py-1"
            variant="btn-primary"
            :disabled="!userName"
            :is-loading=" isUserListLoading"
            text="Search"
            @click="searchUser()"
          />
          <base-button
            variant="btn-link"
            text="Clear"
            @click="clearUser()"
          />
        </div>
        <div class="flex justify-end mb-4">
          <base-pagination
            :model-value="usersData.page"
            :total-pages="usersData.pageCount"
            @update:modelValue="handlePageUpdate($event)"
          />
        </div>
        <div
          v-if="isUserListLoading"
          class="text-center w-full py-10"
        >
          <base-svg
            class="h-4 w-4 mr-1 text-primary-red inline-block"
            src="icons/circleSpinner.svg"
            tag="span"
          />
          Loading ...
        </div>
        <brand-assurance-table
          v-if="!isUserListLoading"
          v-model="usersData.tableData"
          root-element-class="mb-20"
        />
        <div class="flex justify-center">
          <base-button
            class="mr-3 py-1"
            variant="btn-primary"
            :text="mode === 'assign' ? 'Assign' : 'Re-Assign'"
            @click="handleUserAssign()"
          />
          <base-button
            variant="btn-link"
            text="Cancel"
            @click="reset()"
          />
        </div>
      </div>
    </template>
  </base-modal>
</template>

<script>
import { defineAsyncComponent, reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, FETCH_USERS_FAILED, NO_USERS_FOUND, SELECT_ONE_USER, WARNING } from '@/constants/alerts';
import { BAC_ADMIN_ROLE, BAC_REVIEWER_ROLE, BAC_SUPERVISOR_ROLE, CREATIVE_ROLE, LEGAL_ROLE, LICENSEE_ROLE, SAFETY_ROLE, SOCIAL_REVIEWER_ROLE, GAMES_REVIEWER_ROLE, DIGITAL_REVIEWER_ROLE } from '@/constants/ba-roles.js';
import useSubmissionForm from './brand-assurance-submission-form/submissionForm';
import { BAC_FINAL_STEP, BAC_REVIEW_STEP, BAC_SUPERVISOR_STEP, CREATIVE_REVIEW_STEP, LEGAL_REVIEW_STEP, LICENSEE_STEP, SAFETY_STEP, SOCIAL_REVIEW_STEP, GAMES_REVIEW_STEP, DIGITAL_REVIEW_STEP } from '@/constants/submissions.js';

export default {
    name: 'BrandAssuranceAssignTasksModal',

    components: {
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseModal: defineAsyncComponent(() => import('@/components/generic-components/BaseModal.vue')),
        BrandAssuranceTable: defineAsyncComponent(() => import('@/components/BrandAssuranceTable.vue')),
        BasePagination: defineAsyncComponent(() => import('@/components/generic-components/BasePagination.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    props: {
        mode: {
            type: String,
            default: 'assign',
            description: 'flag for switching content for assigning/reassigning tasks'
        },
        selectedSubmission: {
            type: Array,
            default: () => [],
            description: 'Selected submission for fetching user lists'

        }
    },

    emits: [
        'assignUser',
        'hide'
    ],

    setup (props, { emit }) {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();
        const { submissionForm } = useSubmissionForm();

        // assign/reassign user logic
        const userName = ref('');
        const searchUser = () => {
            handlePageUpdate(1);
        };
        const clearUser = () => {
            userName.value = '';
            handlePageUpdate(1);
        };

        const handleUserAssign = () => {
            if (selectedUser.value) {
                emit('assignUser', selectedUser.value);
                reset();
            } else {
                notificationsStack.value.push({
                    type: WARNING,
                    message: SELECT_ONE_USER
                });
            }
        };
        const reset = () => {
            userName.value = '';
            handlePageUpdate(1);
            emit('hide');
        };

        // users table logic
        const usersData = reactive({
            tableData: {
                columns: [
                    {
                        label: 'Users Name',
                        key: 'fullName'
                    },
                    {
                        label: 'User Id',
                        key: 'username'
                    }
                ],
                data: []
            },
            page: 1,
            limit: 5,
            pageCount: 0
        });

        const selectedUser = computed(() => usersData.tableData.data && usersData.tableData.data.length && usersData.tableData.data.filter(user => user.selected).length === 1 ? (usersData.tableData.data.find(user => user.selected) || { username: '' }).username : '');
        const fetchedUsersList = computed(() => {
            const usersList = store.getters['users/getUsersList'];
            if (usersList?.data) {
                usersList.data = usersList.data.map(user => {
                    return {
                        ...user,
                        fullName: `${user.firstname} ${user.lastname}`
                    };
                });
            }
            return usersList;
        });
        const isUserListLoading = ref(false);
        const fetchUsersList = async () => {
            if (isUserListLoading.value) {
                return;
            }
            try {
                isUserListLoading.value = true;
                let roleName;
                let isMatchAllRoles = false;
                let checkMatchAllOpenSubmission = false;
                if (props.selectedSubmission.length > 0) {
                    const tempRoleName = [];
                    props.selectedSubmission.forEach(selected => {
                        if (selected.currentStep === BAC_REVIEW_STEP) tempRoleName.push(BAC_REVIEWER_ROLE);
                        if (selected.currentStep === LEGAL_REVIEW_STEP) tempRoleName.push(LEGAL_ROLE);
                        if (selected.currentStep === BAC_SUPERVISOR_STEP) tempRoleName.push(BAC_SUPERVISOR_ROLE);
                        if (selected.currentStep === SOCIAL_REVIEW_STEP) tempRoleName.push(SOCIAL_REVIEWER_ROLE);
                        if (selected.currentStep === GAMES_REVIEW_STEP) tempRoleName.push(GAMES_REVIEWER_ROLE);
                        if (selected.currentStep === SAFETY_STEP) tempRoleName.push(SAFETY_ROLE);
                        if (selected.currentStep === CREATIVE_REVIEW_STEP) tempRoleName.push(CREATIVE_ROLE);
                        if (selected.currentStep === LICENSEE_STEP) tempRoleName.push(LICENSEE_ROLE);
                        if (selected.currentStep === BAC_FINAL_STEP) tempRoleName.push(BAC_ADMIN_ROLE);
                        if (selected.currentStep === DIGITAL_REVIEW_STEP) tempRoleName.push(DIGITAL_REVIEWER_ROLE);
                    });
                    isMatchAllRoles = true;
                    roleName = [...new Set(tempRoleName)];
                } else {
                    roleName = '';
                    if (submissionForm.currentStepName === CREATIVE_REVIEW_STEP) {
                        roleName = CREATIVE_ROLE;
                    } else if (submissionForm.currentStepName === LEGAL_REVIEW_STEP) {
                        roleName = LEGAL_ROLE;
                    } else if (submissionForm.currentStepName === BAC_SUPERVISOR_STEP) {
                        roleName = BAC_SUPERVISOR_ROLE;
                    } else if (submissionForm.currentStepName === BAC_REVIEW_STEP) {
                        roleName = BAC_REVIEWER_ROLE;
                    } else if (submissionForm.currentStepName === SAFETY_STEP) {
                        roleName = SAFETY_ROLE;
                    } else if (submissionForm.currentStepName === SOCIAL_REVIEW_STEP) {
                        roleName = SOCIAL_REVIEWER_ROLE;
                    } else if (submissionForm.currentStepName === DIGITAL_REVIEW_STEP) {
                        roleName = DIGITAL_REVIEWER_ROLE;
                    } else if (submissionForm.currentStepName === GAMES_REVIEW_STEP) {
                        roleName = GAMES_REVIEWER_ROLE;
                    } else if (submissionForm.currentStepName === LICENSEE_STEP) {
                        roleName = LICENSEE_ROLE;
                    } else {
                        // in case of BAC FINAL
                        roleName = BAC_ADMIN_ROLE;
                    }
                    checkMatchAllOpenSubmission = true;
                }

                await store.dispatch('users/fetchUsersList', {
                    params: {
                        page: usersData.page,
                        limit: usersData.limit,
                        searchScope: userName.value ? 'username,firstname,lastname' : null,
                        searchKeyword: userName.value ? `${userName.value},${userName.value},${userName.value}` : null,
                        rolename: roleName.toString(),
                        matchAllRoles: !!(props.selectedSubmission.length >= 1 || checkMatchAllOpenSubmission) // to check while submission opened
                    }
                });
                updateUsersData();
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: FETCH_USERS_FAILED
                });
            } finally {
                isUserListLoading.value = false;
            }
        };
        const updateUsersData = () => {
            usersData.tableData.data = fetchedUsersList.value.data;
            usersData.pageCount = fetchedUsersList.value.pageCount;
            if (usersData.tableData.data?.length === 0) {
                notificationsStack.value.push({
                    type: WARNING,
                    message: NO_USERS_FOUND
                });
            }
        };
        const handlePageUpdate = (newPage) => {
            usersData.page = newPage;
            fetchUsersList();
        };

        return {
            // assign/reassign user
            userName,
            searchUser,
            clearUser,
            handleUserAssign,
            reset,
            // users table
            selectedUser,
            usersData,
            isUserListLoading,
            fetchUsersList,
            handlePageUpdate
        };
    }
};
</script>
